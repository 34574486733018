<template>
    <div>
        <a-alert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <ARow :gutter="[8, 8]">
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterDistributorCurah
                    style="width:100%;"
                    v-model:value="state.params.distributor"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <ASelect
                    style="width:100%;"
                    placeholder="Pilih Tahun"
                    allow-clear
                    :options="state.yearLists"
                    v-model:value="state.params.year"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <AButton
                    type="primary"
                    title="cari"
                    @click="fetchDataList"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </ACol>
        </ARow>

        <div class="row justify-content-end mt-4">
            <div class="col-lg-6 col-md-12"></div>
            <div class="col-lg-6 col-md-12 text-right">
                <DownloadExcel
                    :url="state.endpoint"
                    :params="queryParams()"
                    namefile="Laporan-Keuangan"
                    @errors="errorMessage"/>
            </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <TableStandard
                v-if="!state.isFetching"
                :url="state.endpoint"
                :columns="state.columns"
                :params="queryParams()"
                @errors="errorMessage"/>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, nextTick, onMounted, h } from 'vue'
import FilterDistributorCurah from '@/components/filter/FilterDistributorCurah'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import TableStandard from '@/components/Molecules/TableStandard'
import { pickBy } from 'lodash'
import moment from 'moment'

export default defineComponent({
    components: {
        DownloadExcel,
        TableStandard,
        FilterDistributorCurah,
    },
    setup() {
        const errorMessage = ref()
        const state = reactive({
            columns: [
                {
                    title: 'NO',
                    dataIndex: 'no',
                    slots: { customRender: 'no' },
                },
                {
                    title: 'Kode Distributor',
                    dataIndex: 'vendor_code',
                },
                {
                    title: 'Nama Distributor',
                    dataIndex: 'vendor_name',
                },
                {
                    title: 'Tahun',
                    dataIndex: 'year',
                },
                {
                    title: 'Kuartal',
                    dataIndex: 'quartal',
                },
                {
                    title: 'Catatan',
                    dataIndex: 'note',
                },
                {
                    title: 'Berkas Neraca',
                    dataIndex: 'file_balance_sheet',
                    customRender: ({ text }) => {
                        return h(
                            'a', 
                                {
                                    href: text,
                                    target: "_blank",
                                    rel: "noopener noreferrer",
                                },
                                text,
                            );
                    },
                },
                {
                    title: 'Berkas Arus Kas',
                    dataIndex: 'file_cashflow',
                    customRender: ({ text }) => {
                        return h(
                            'a', 
                                {
                                    href: text,
                                    target: "_blank",
                                    rel: "noopener noreferrer",
                                },
                                text,
                            );
                    },
                },
                {
                    title: 'Berkas Laba Rugi',
                    dataIndex: 'file_profit_loss',
                    customRender: ({ text }) => {
                        return h(
                            'a',
                            {
                                href: text,
                                target: "_blank",
                                rel: "noopener noreferrer",
                            },
                            text,
                        )
                    },
                },
            ],
            endpoint:'/api/report/finance',
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            isDownloading: false,
            params: {
                q: '',
                distributor: [],
                year: moment(new Date).format('Y'),
                page: 1,
                "per-page": 10,
            },
            yearLists: [],
        })

        const queryParams = () => {
            let params = state.params

            return pickBy(params)
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true
            
            nextTick().then(() => {
                state.isFetching = false
            })
        }

        onMounted(() => {
            const currentYear = new Date().getFullYear()
            // Membuat array yearOptions dengan format { value, label }
            state.yearLists = Array.from({ length: 10 }, (_, i) => {
                const year = currentYear - i;
                return { value: year, label: String(year) }
            })
        })

        return {
            state,
            errorMessage,
            fetchDataList,
            queryParams,
        }
    },
})
</script>
